export function prefetchBookingPage() {
  getBig();
  getSmall();
}

export const getBig = () =>
  import("@/lib/booking/big-new.client").then((c) => ({
    default: c.BookingTableBigNew,
  }));

export const getSmall = () =>
  import("@/lib/booking/mobile.client").then((c) => ({
    default: c.BookingTableSmall,
  }));
