import { getCurrentLang, t } from "@/lib/translations";
import { Button, Tooltip } from "@mantine/core";

export function LanguageSelect() {
  return (
    <div className="flex flex-col gap-3 mt-4">
      <div className="flex gap-2 items-center">
        <Tooltip label={t("ROOT.FOOTER.LANG_POLISH_TEXT")}>
          <Button
            component={"a"}
            href="/#"
            variant={getCurrentLang() === "pl" ? "outline" : undefined}
            aria-label={t("ROOT.FOOTER.LANG_POLISH_TEXT")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let newPathname = window.location.pathname.replace("/en", "");
              if (newPathname === "") {
                newPathname = "/";
              }
              window.location.href = newPathname;
            }}
          >
            PL
          </Button>
        </Tooltip>
        <div>/</div>
        <Tooltip label={t("ROOT.FOOTER.LANG_ENGLISH_TEXT")}>
          <Button
            component={"a"}
            href="/en/#"
            aria-label={t("ROOT.FOOTER.LANG_ENGLISH_TEXT")}
            variant={getCurrentLang() === "en" ? "outline" : undefined}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newPathname = "/en" + window.location.pathname;
              window.location.href = newPathname;
            }}
          >
            EN
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
