import { apiClient } from "@/lib/api/client";
import { globalAppSettings } from "@/lib/cms-data.generated";
import { prefetchBookingPage } from "@/pages/booking/components";
import { theme } from "@/theme";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { Footer } from "./Footer";
import { BigHeader, HeaderOffetWatcher, SmallHeader } from "./Header";

export type AppLayoutProps = {
  children: React.ReactNode;
};
export function AppLayout({ children }: AppLayoutProps) {
  useEffect(() => {
    requestIdleCallback(() => {
      prefetchBookingPage();
      // we want to prefetch and cache cors
      apiClient.GET("/health");
    });
  }, []);

  return (
    <MantineProvider theme={theme} defaultColorScheme="dark">
      <Notifications />

      <header
        className={`
          mx-auto flex justify-between
          h-header max-h-header fixed top-0 left-0 w-full
          bg-black/0
          data-[moved]:shadow data-[moved]:shadow-slate-800  z-[100]
          data-[moved]:backdrop-blur-sm
          transition-all
          duration-300
      `}
      >
        <HeaderOffetWatcher />
        <BigHeader settings={globalAppSettings.global} />
        <SmallHeader settings={globalAppSettings.global} />
      </header>

      <main className="mt-header">{children}</main>
      <Footer settings={globalAppSettings.global} />
    </MantineProvider>
  );
}
