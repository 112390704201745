import type { OnPageTransitionStartAsync } from "vike/types";

export const onPageTransitionStart: OnPageTransitionStartAsync = async () => {
  document.querySelector("body")?.classList.add("page-is-transitioning");

  document.dispatchEvent(
    new CustomEvent("dg-navigation", {
      detail: {
        type: "before",
      },
    }),
  );
};
