import { SettingsModel } from "@/lib/api/models";
import { useNavigationStore } from "@/lib/hooks/useNavigationStore";
import { IconBrandInstagram } from "@/lib/icons/tabler/IconBrandInstagram";
import { IconCalendar } from "@/lib/icons/tabler/IconCalendar";
import { IconExternalLink } from "@/lib/icons/tabler/IconExternalLink";
import { IconUser } from "@/lib/icons/tabler/IconUser";
import { t } from "@/lib/translations";
import logo from "@/logo.svg";
import { getDataManagemntComponent } from "@/pages/data-management/components";
import { getLoginPageComponent } from "@/pages/data-management/login/components";
import { ActionIcon, Divider, Tooltip } from "@mantine/core";
import { useInViewport } from "@mantine/hooks";
import clsx from "clsx";
import { useEffect } from "react";
import { AppImage } from "./AppImage";
import { AppLink } from "./AppLink";
import { LanguageSelect } from "./LangaugeSelect";
export function Footer({ settings }: { settings: SettingsModel }) {
  return (
    <>
      <div className={"pt-24 mt-24 mb-12"}>
        <Divider orientation="horizontal" />
        <div
          className="
          flex flex-col gap-8 pt-8
          md:flex-row md:justify-between md:max-w-[64rem] md:mx-auto md:px-12
        "
        >
          <FooterLinks settings={settings} />
          <div className="h-full my-auto md:pb-12">
            <AppLink to="/#">
              <AppImage
                alt={t("ROOT.DETERMINACJA_GYM_LOGO_ALT")}
                src={logo}
                className="w-[200px] h-[200px] mx-auto my-auto md:mx-0"
              />
            </AppLink>
          </div>
        </div>
      </div>
    </>
  );
}

function FooterLinks({ settings }: { settings: SettingsModel }) {
  const { activeLink } = useNavigationStore();

  const { ref, inViewport } = useInViewport();

  useEffect(() => {
    if (inViewport) {
      getDataManagemntComponent();
      getLoginPageComponent();
    }
  }, [inViewport]);

  return (
    <div className="flex flex-col justify-center gap-6 p-4" ref={ref}>
      <Tooltip label="Instagram">
        <AppLink to={settings.instagramLink} target="_blank" className="flex gap-6 items-center">
          <ActionIcon variant="subtle" name="Instagram" aria-label="Instagram">
            <IconBrandInstagram />
          </ActionIcon>
          <p className="shoju">Instagram</p>
        </AppLink>
      </Tooltip>

      <Tooltip label={t("ROOT.FOOTER.CONTACT_LINK_TEXT")}>
        <AppLink to="/#contact" className="flex gap-6 items-center">
          <ActionIcon
            variant="subtle"
            name={t("ROOT.FOOTER.CONTACT_LINK_TEXT")}
            aria-label={t("ROOT.FOOTER.CONTACT_LINK_TEXT")}
          >
            <IconExternalLink />
          </ActionIcon>
          <p
            className={clsx("shoju", {
              "text-brand-600": activeLink === "/#contact",
            })}
          >
            {t("ROOT.FOOTER.CONTACT_LINK_TEXT")}
          </p>
        </AppLink>
      </Tooltip>

      <Tooltip label={t("ROOT.FOOTER.PRIVACY_POLITY_LINK_TEXT")}>
        <AppLink to={"/privacy-policy"} className="flex gap-6 items-center">
          <ActionIcon
            variant="subtle"
            name={t("ROOT.FOOTER.PRIVACY_POLITY_LINK_TEXT")}
            aria-label={t("ROOT.FOOTER.PRIVACY_POLITY_LINK_TEXT")}
          >
            <IconExternalLink />
          </ActionIcon>
          <p
            className={clsx("shoju", {
              "text-brand-600": activeLink === "/privacy-policy",
            })}
          >
            {t("ROOT.FOOTER.PRIVACY_POLITY_LINK_TEXT")}
          </p>
        </AppLink>
      </Tooltip>

      <Tooltip label={t("ROOT.FOOTER.MY_ACCOUNT_LINK_TEXT")}>
        <AppLink to="/data-management" className="flex gap-6 items-center" prefetch="true">
          <ActionIcon
            variant="subtle"
            name={t("ROOT.FOOTER.MY_ACCOUNT_LINK_TEXT")}
            aria-label={t("ROOT.FOOTER.MY_ACCOUNT_LINK_TEXT")}
          >
            <IconUser />
          </ActionIcon>
          <p
            className={clsx("shoju", {
              "text-brand-600": activeLink === "/data-management",
            })}
          >
            {t("ROOT.FOOTER.MY_ACCOUNT_LINK_TEXT")}
          </p>
        </AppLink>
      </Tooltip>

      <Tooltip label={t("ROOT.FOOTER.BOOKING_LINK_TEXT")}>
        <AppLink to="/booking" className="flex gap-6 items-center">
          <ActionIcon
            variant="subtle"
            name={t("ROOT.FOOTER.BOOKING_LINK_TEXT")}
            aria-label={t("ROOT.FOOTER.BOOKING_LINK_TEXT")}
          >
            <IconCalendar />
          </ActionIcon>
          <p
            className={clsx("shoju", {
              "text-brand-600": activeLink === "/booking",
            })}
          >
            {t("ROOT.FOOTER.BOOKING_LINK_TEXT")}
          </p>
        </AppLink>
      </Tooltip>

      <LanguageSelect />
    </div>
  );
}
