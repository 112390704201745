import { AppImage } from "@/components/AppImage";
import { SettingsModel } from "@/lib/api/models";
import { useNavigationStore } from "@/lib/hooks/useNavigationStore";
import { IconBrandInstagram } from "@/lib/icons/tabler/IconBrandInstagram";
import { IconCalendar } from "@/lib/icons/tabler/IconCalendar";
import { IconCoinBitcoin } from "@/lib/icons/tabler/IconCoinBitcoin";
import { IconHome } from "@/lib/icons/tabler/IconHome";
import { IconPhoneCall } from "@/lib/icons/tabler/IconPhoneCall";
import { IconPhoto } from "@/lib/icons/tabler/IconPhoto";
import { IconUser } from "@/lib/icons/tabler/IconUser";
import { IconUsersGroup } from "@/lib/icons/tabler/IconUsersGroup";
import { t } from "@/lib/translations";
import { ActionIcon, Box, Burger, Divider, Drawer, Group, ScrollArea, Tooltip, rem } from "@mantine/core";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";

import { getDataManagemntComponent } from "@/pages/data-management/components";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import logo from "../logo.svg";
import { AppLink } from "./AppLink";
import { getLoginPageComponent } from "@/pages/data-management/login/components";

type AppLinkType = {
  link: string;
  activeFallback?: string;
  label: string;
  icon: React.ReactElement;
  prefetch: string | undefined;
};

const getAppLinks = (): AppLinkType[] => {
  return [
    { link: "/#", activeFallback: "/", label: "ROOT.NAVIGATION.HOME", icon: <IconHome />, prefetch: undefined },
    {
      link: "/#team",
      label: "ROOT.NAVIGATION.OUR_TEAM",
      icon: <IconUsersGroup />,
      prefetch: undefined,
    },
    {
      link: "/#pricing",
      label: "ROOT.NAVIGATION.PRICING",
      icon: <IconCoinBitcoin />,
      prefetch: undefined,
    },
    {
      link: "/#contact",
      label: "ROOT.NAVIGATION.CONTACT",
      icon: <IconPhoneCall />,
      prefetch: undefined,
    },
    {
      link: "/gallery",
      label: "ROOT.NAVIGATION.GALLERY",
      icon: <IconPhoto />,
      prefetch: "true",
    },
  ];
};

function getActivePage(path: string, hash: string) {
  if (path === "/") {
    if (!hash || hash === "") {
      return "/";
    }

    return `/${hash}`;
  }

  return path;
}

export function TitleSmallHeader({ onClick }: { onClick?: () => void }) {
  return (
    <AppLink to="/" className="" onClick={onClick}>
      <img alt={t("ROOT.DETERMINACJA_GYM_LOGO_ALT")} src={logo} className="w-[100px] h-[100px] " />
    </AppLink>
  );
}

export function SmallHeader({ settings }: { settings: SettingsModel }) {
  const activePage = useNavigationStore((s) => s.activeLink);
  const [opened, { toggle }] = useDisclosure();

  useEffect(() => {
    // prefetch login page
    getDataManagemntComponent();
    getLoginPageComponent()
  }, []);

  const items = getAppLinks().map((link) => {
    const isActive = activePage === link.link || activePage === link.activeFallback;

    return (
      <Box
        key={link.link}
        component={AppLink}
        to={link.link}
        c={isActive ? "brand.6" : undefined}
        className={clsx("flex gap-6 items-center hover:text-brand-500", {
          "": isActive,
        })}
        onClick={() => {
          toggle();
        }}
      >
        {link.icon}
        <p className="">{t(link.label)}</p>
      </Box>
    );
  });

  return (
    <>
      <div className="flex flex-1 justify-between min-[950px]:hidden items-center px-6 py-4">
        <AppLink to="/" className="">
          <img alt="Determinacja gym logo" src={logo} className="w-[100px] h-[100px] " />
        </AppLink>

        <Burger opened={opened} onClick={toggle} aria-label="Toggle navigation" />

        <Drawer
          opened={opened}
          onClose={() => {
            toggle();
          }}
          size="100%"
          title={
            <TitleSmallHeader
              onClick={() => {
                toggle();
              }}
            />
          }
          hiddenFrom="sm"
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(0)})`} my="-xl" pt="xl" className="px-6">
            <div className="flex flex-col gap-4">{items}</div>

            <Divider orientation="horizontal" className="my-4 mt-8" />

            <SmallHeaderBigLinks toggle={toggle} settings={settings} />
          </ScrollArea>
        </Drawer>
      </div>
    </>
  );
}

export function SmallHeaderBigLinks({ settings, toggle }: { settings: SettingsModel; toggle: () => void }) {
  const { activeLink } = useNavigationStore();

  return (
    <div className="flex flex-col gap-4 pt-2">
      <AppLink
        to={settings.instagramLink}
        target="_blank"
        rel="noreferrer"
        className="flex gap-6 items-center"
        onClick={() => {
          toggle();
        }}
      >
        <ActionIcon variant="subtle">
          <IconBrandInstagram />
        </ActionIcon>
        <p className="shoju">Instagram</p>
      </AppLink>

      <AppLink
        to="/booking"
        className={clsx("flex gap-6 items-center", {
          "text-brand-600": activeLink === "/booking",
        })}
        onClick={() => {
          toggle();
        }}
      >
        <ActionIcon variant="subtle">
          <IconCalendar />
        </ActionIcon>
        <p className="shoju">{t("ROOT.NAVIGATION.RESERVATIONS")}</p>
      </AppLink>

      <AppLink
        to="/data-management"
        className={clsx("flex gap-6 items-center", {
          "text-brand-600": activeLink === "/data-management",
        })}
        onClick={() => {
          toggle();
        }}
      >
        <ActionIcon variant="subtle">
          <IconUser />
        </ActionIcon>
        <p className="shoju">{t("ROOT.MY_ACCOUNT")}</p>
      </AppLink>
    </div>
  );
}

export function HeaderOffetWatcher() {
  const [scroll, scrollTo] = useWindowScroll();
  const pageMoved = useRef(false);

  useEffect(() => {
    if (scroll.y > 100) {
      if (!pageMoved.current) {
        pageMoved.current = true;
        document.querySelector("header")?.setAttribute("data-moved", "");
      }
    } else {
      if (pageMoved.current) {
        pageMoved.current = false;
        document.querySelector("header")?.removeAttribute("data-moved");
      }
    }
  }, [scroll]);

  return <></>;
}

function BigHeaderLink({ link }: { link: AppLinkType }) {
  const { activeLink } = useNavigationStore();

  const isActiveLink = activeLink === link.link || activeLink === link.activeFallback;

  return (
    <Box
      component={AppLink}
      key={`${link.label}-${activeLink}`}
      to={link.link}
      prefetch={link.prefetch}
      c={isActiveLink ? "brand.6" : undefined}
      className="hover:text-brand-500"
    >
      {t(link.label)}
    </Box>
  );
}

export function BigHeader({ settings }: { settings: SettingsModel }) {
  const activeLink = useNavigationStore((s) => s.activeLink);
  const items = getAppLinks().map((link) => <BigHeaderLink key={link.link} link={link} />);

  return (
    <div className="flex flex-1 justify-between max-[950px]:hidden px-16">
      <AppLink to="/" className="flex items-center justify-center" aria-label={t("ROOT.DETERMINACJA_GYM_LOGO_ALT")}>
        <AppImage alt={t("ROOT.DETERMINACJA_GYM_LOGO_ALT")} src={logo} className="w-[110px] h-[110px] " />
      </AppLink>

      <nav className="flex gap-3 items-center">
        <Group gap={24}>{items}</Group>
        <Divider orientation="vertical" className="mx-4 my-auto" h={"15%"} />

        <div className="flex gap-4 items-center">
          <Tooltip label={t("ROOT.NAVIGATION.RESERVATIONS")}>
            <ActionIcon
              aria-label={t("ROOT.NAVIGATION.RESERVATIONS")}
              variant="subtle"
              component={AppLink}
              to="/booking"
              className={activeLink === "/booking" ? "actionLinkActive" : undefined}
              prefetch="true"
            >
              <IconCalendar />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Instagram">
            <ActionIcon
              aria-label="Instagram"
              variant="subtle"
              component={AppLink}
              to={settings.instagramLink}
              target="_blank"
            >
              <IconBrandInstagram />
            </ActionIcon>
          </Tooltip>
        </div>
      </nav>
    </div>
  );
}
