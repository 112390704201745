import { getCurrentLang } from "@/lib/translations";
import { forwardRef, useEffect } from "react";
import { prefetch } from "vike/client/router";

// Use React.AnchorHTMLAttributes for native anchor tag props
export type AppLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to?: string;
  href?: string; // making href optional
  children: React.ReactNode;
  prefetch?: string;
  relative?: string;
};

export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(({ to, href, ...props }, ref) => {
  let toLink = href ?? to ?? "";

  if (getCurrentLang() === "en") {
    toLink = "/en" + toLink;
  }

  useEffect(() => {
    if (props.prefetch) {
      prefetch(toLink);
    }
  }, [props.prefetch]);

  return (
    <a ref={ref} {...props} href={toLink}>
      {props.children}
    </a>
  );
});

AppLink.displayName = "AppLink";
