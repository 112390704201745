import { appMetrics } from "./lib/metrics";
import { getLangFromUri, setCurrentLang } from "./lib/translations";

// method that must be called before hydration happens
export function onPageStart() {
  registerErrorHandlers();
  setCurrentLang(getLangFromUri(location.href));
  appMetrics.start();

  if (!("requestIdleCallback" in window)) {
    // @ts-ignore
    window.requestIdleCallback = (fn: () => void, args?: { timeout?: number }) => {
      setTimeout(fn, args?.timeout ?? 200);
    };
  }

  console.log(`page initialized!`);
}

function registerErrorHandlers() {
  if (!localStorage.getItem("dgdebug")) {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    window.onerror = (message, source, lineno, colno, error) => {
      handleDynamicImportError(error);

      if (error) {
        appMetrics.addError(error?.message);
      }

      return true;
    };
    window.onrejectionhandled = (event) => {
      handleRejection(event.reason);

      appMetrics.addError(event.reason);

      return true;
    };
  } else {
    window.onerror = (message, source, lineno, colno, error) => {
      handleDynamicImportError(error);
      if (error) {
        appMetrics.addError(error?.message);
      }
      return false;
    };

    window.onunhandledrejection = (event) => {
      handleRejection(event.reason);
      appMetrics.addError(event.reason);
    };
  }
}

const errMsg = "Failed to fetch dynamically imported module";

function handleDynamicImportError(err: Error | undefined) {
  if (err?.message?.includes(errMsg)) {
    console.log(`handling [${errMsg}] error`);
    // location.reload();
  }
}

function handleRejection(err: any) {
  if (typeof err === "string") {
    if (err?.includes(errMsg)) {
      console.log(`handling [${errMsg}] error`);
      // location.reload();
    }
  }
}
